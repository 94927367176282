// Toggle Sidebar
$(document).on('click', '[data-toggle="sidebar"]', function (event) {
    event.preventDefault();
    $('body').toggleClass('sidenav-toggled');

    changeMenuStateTheme($('.app').hasClass('sidenav-toggled') ? 1 : 0);
});

// Activate sidebar slide toggle
$(document).on("click", "[data-toggle='slide']", function (event) {
    event.preventDefault();
    if (!$(this).parent().hasClass('is-expanded')) {
        $('.side-menu').find("[data-toggle='slide']").parent().removeClass('is-expanded');
    }
    $(this).parent().toggleClass('is-expanded');
});

// Set initial active toggle
$(document).on("click", "[data-toggle='sub-slide']", function (event) {
    event.preventDefault();
    if (!$(this).parent().hasClass('is-expanded')) {
        $('.side-menu').find("[data-toggle='sub-slide']").parent().removeClass('is-expanded');
    }
    $(this).parent().toggleClass('is-expanded');
    $('.slide.active').addClass('is-expanded');
});

// Set initial active toggle
$("[data-toggle='slide.'].is-expanded").parent().toggleClass('is-expanded');
$("[data-toggle='sub-slide.'].is-expanded").parent().toggleClass('is-expanded');

// ______________Active Class
$(".app-sidebar li a").each(function () {
    let pageUrl = window.location.href.split(/[?#]/)[0];
    if (this.href === pageUrl) {
        let selector = $(this);
        selector.addClass("active");
        selector.parent().addClass("active"); // add active to li of the current link
        selector.parent().parent().prev().addClass("active"); // add active class to an anchor
        selector.parent().parent().parent().parent().parent().addClass("active");
        selector.parent().parent().prev().click(); // click the item to make it drop
    }
});
