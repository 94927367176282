<template>
    <div :id="getFieldId()"
         class="cms-image-picker col-6 col-xl-2"
         v-bind:class="{placeholder: isPlaceholder, multi: isMulti, 'reorder-handle': !isEmpty}">
        <div class="cms-image-picker--wrapper">
            <i class="alt-icon fa-solid fa-alt" title="Alt has been properly filled"
               v-if="!isPlaceholder && (typeof this.attrAlt !== 'undefined' && this.attrAlt.length > 0)"></i>
            <span class="alt-icon fa-layers fa-fw"
                  v-if="!isPlaceholder && (typeof this.attrAlt === 'undefined' || this.attrAlt === null || this.attrAlt.length <= 0)"
                  title="Alt is not properly filled">
                <i class="fa-solid fa-ban"></i>
                <i class="fa-regular fa-alt" data-fa-transform="shrink-10"></i>
            </span>
            <input type="hidden" :name="getAttrName('url')"
                   class="cms-image-picker--vue"
                   :class="this.attrClass"
                   :id="getFieldId('url')"
                   :value="attrUrl">
            <input type="hidden" :name="getAttrName('focalPoint')"
                   :id="getFieldId('focalPoint')"
                   :class="this.attrClass"
                   :value="focalPointAttr">
            <input type="hidden" :name="getAttrName('title')"
                   :id="getFieldId('title')"
                   :class="this.attrClass"
                   :value="this.attrTitle">
            <input type="hidden" :name="getAttrName('alt')"
                   :id="getFieldId('alt')"
                   :class="this.attrClass"
                   :value="this.attrAlt">
            <input type="hidden" :name="getAttrName('description')"
                   :id="getFieldId('description')"
                   :class="this.attrClass"
                   :value="this.attrDescription">
            <div class="plus-placeholder" v-bind:class="{empty: isEmpty}"
                 :style="backgroundImageStyle"></div>
            <div class="trash" v-bind:class="{empty: isEmpty}"
                 v-on:click="showConfirmationDialog('Are you sure you want to remove this media ?')">
                <i class="far fa-trash-alt"></i>
            </div>
            <div v-if="isMulti && !isEmpty" class="drag" v-bind:class="{empty: isEmpty, 'reorder-handle': !isEmpty}"><i
                class="fas fa-exchange-alt"></i></div>
        </div>
    </div>
</template>

<style scoped>
.alt-icon {
    z-index        : 9999;
    position       : absolute;
    top            : 5px;
    right          : 5px;
    color          : white;
    mix-blend-mode : difference;
}

.dark-mode .cms-image-picker--wrapper .plus-placeholder.empty:before {
    color : rgb(133, 139, 167) !important;
}
</style>

<script>
export default {
    props  : {
        identification: {type: String},
        elementKey    : {type: String},
        sizes         : {type: Object, default: null},
        definition    : {type: Object, default: null},
        imageUrl      : {type: String},
        bsu           : {type: String, default: ''},
        multi         : {default: false},
        placeholder   : {type: Boolean, default: false},
        overrideName  : {type: Boolean, default: false},
        attrName      : {type: String, default: ""},
        attrClass     : {type: String, default: ""},
    },
    methods: {
        clear                 : function () {
            //this.url = null;
            //console.log('#' + this.getFieldId() + " input");
            $('#' + this.getFieldIdEscaped() + " input").attr('value', '');
            $('#' + this.getFieldIdEscaped() + " #" + this.getFieldIdEscaped('focalPoint')).val('50%,50%');
            $('#' + this.getFieldIdEscaped() + " .plus-placeholder").attr('style', '').addClass('empty');
        },
        trash                 : function () {
            if (this.isMulti) {
                this.$parent.mediaPickers.splice(this.getFieldId());
                //this.$parent.items.splice(this.definition);

                this.$destroy();
                this.clear();
                this.$el.parentNode.removeChild(this.$el);
            } else {
                this.clear();
            }
        },
        getAttrName           : function (fieldName) {
            let component = this;
            if (component.multi) {
                return component.overrideName ? this.attrName + '.' + component.currentId + '.' + fieldName : 'form_input_' + component.elementKey + '[' + component.currentId + '][' + fieldName + ']'
            }
            return component.overrideName ? this.attrName + '.' + fieldName : 'form_input_' + component.elementKey + '[' + fieldName + ']';
        },
        getFieldId            : function (fieldName = null, fieldId = null) {
            let component = this;
            let name      = component.identification;
            if (component.multi) {
                name = name + '_' + (fieldId ?? this.currentId);
            }
            if (fieldName !== null) {
                name = name + "_" + fieldName;
            }
            return name;
        },
        getFieldIdEscaped     : function (fieldName = null, fieldId = null) {
            //console.log("FieldName: ", fieldName, "FieldId:", fieldId)
            let component = this;
            return component.getFieldId(fieldName, fieldId).replace('.', '\\.');
        },
        showConfirmationDialog: function (text) {
            let component = this;
            bootbox.confirm(
                text,
                function (result) {
                    if (result) {
                        component.trash()
                    }
                })
        },
        dataChanged           : function () {
            let component  = this;
            let urlValue   = $('#' + component.getFieldIdEscaped('url')).val();
            let focalPoint = $('#' + component.getFieldIdEscaped('focalPoint')).val();
            //console.log("Data changed: " + urlValue);
            if (!component.placeholder) {
                component.url = urlValue;
            }

            let positions = focalPoint.replace(',', ' ');
            let selector  = `#${component.getFieldIdEscaped()} .plus-placeholder`;

            if (component.placeholder && typeof component.$parent !== 'undefined') {
                if (typeof component.$parent.items === 'undefined' || component.$parent.items === null) {
                    component.$parent.items = [];
                }
                component.$parent.items.push({url: urlValue});
                component.clear();
            } else {
                this.$nextTick(function () {
                    //console.log(positions, urlValue);

                    $(selector).css('background-position', positions)
                        .css('background-image', `url(${urlValue})`);
                    if (urlValue !== '') {
                        $(selector).removeClass('empty');
                    }
                });
            }
        }
    },

    created() {
        var component = this;
        //console.log("component", component);
        if (!this.placeholder) {
            this.currentId = this.isPlaceholder ? 'placeholder' : this.$parent.mediaPickerCount++;
            if (this.multi) {
                if (!this.$parent.mediaPickers.includes(this.getFieldId())) {
                    this.$parent.mediaPickers.push(this.getFieldId())
                } else {
                    bootbox.alert(
                        "This image is already in the list.",
                        function () {
                            component.trash();
                        }
                    );
                }
            }
        }
    },
    mounted() {
        var baseSelector = "#" + this.getFieldIdEscaped();
        var selector     = baseSelector + " > .cms-image-picker--wrapper";

        window.upload_btn_use_id = '';
        var component            = this;

        $('#app').on('click', selector + " > .plus-placeholder", function (e) {
            e.preventDefault();
            var el = $(this);
            if (el.hasClass('empty')) {
                window.upload_btn_use_id  = component.getFieldIdEscaped('url');
                window.upload_btn_back_fn = function () {
                    component.dataChanged();
                };
                window.open('/file-manager/fm-button', 'fm', 'width=1400,height=800');
            } else {
                mediaModalInit(baseSelector);
            }
        });
    },
    data() {
        return {
            isMulti      : this.multi !== null && this.multi !== false,
            isPlaceholder: this.placeholder !== null && this.placeholder !== false,
            url          : this.imageUrl,
            storageUrl   : this.bsu ?? '',
            def          : this.definition ?? {url: null}
        }
    },
    computed: {
        attrUrl             : function () {
            var url = this.url ?? ((typeof this.def.url !== 'undefined' && this.def.url !== null) ? this.def.url : '')
            if (typeof url === 'string' || url instanceof String) {
                url = url.replaceAll(' ', '%20');
            }
            return url;
        },
        attrAlt             : function () {
            return this.def && this.def.alt !== null ? this.def.alt : ''
        },
        attrTitle           : function () {
            return this.def && this.def.title !== null ? this.def.title : ''
        },
        attrDescription     : function () {
            return this.def && this.def.description !== null ? this.def.description : ''
        },
        isEmpty             : function () {
            //console.log(this.url, this.def, typeof this.def === 'undefined', this.def.url)
            //return this.url === null && (_.isEmpty(this.def) || this.def === null || typeof this.def === 'undefined' || this.def.url === null)
            return this.attrUrl === null || this.attrUrl === '';
        },
        backgroundImageStyle: function () {
            if (this.isEmpty) {
                return ''
            }

            var url = this.attrUrl ?? this.url;
            if (!url.startsWith('http')) {
                url = this.storageUrl + url;
            }

            return 'z-index:2; background-image: url(\'' + url + '\'); background-position: ' + this.focalPointAttr.replace(',', ' ');
        },
        focalPointAttr      : function () {
            return (this.def !== null &&
                typeof this.def !== 'undefined' &&
                this.def.hasOwnProperty('focalPoint') &&
                this.def.focalPoint !== null) ? this.def.focalPoint : "50%,50%";
        }
    }
}
</script>
