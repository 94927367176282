<template>
    <div class="col-12">
        <div :id="getFieldId()"
             class="row cms-image-picker-row" v-bind:class="{multi: isMulti}">
            <image-picker v-for="item in items" :key="item.url"
                          v-if="item.url !== null"
                          :definition="item"
                          :identification="identification"
                          :multi="true"
                          :url="item.url"
                          :element-key="elementKey">
            </image-picker>

            <image-picker v-if="canAddAnother"
                          :identification="identification"
                          :definition="{}"
                          :multi="true"
                          :placeholder="true"
                          :element-key="elementKey">
            </image-picker>

        </div>
        <div class="row">
            <div class="col-12">
                <div v-if="maxImages !== 9999">{{ imagesCount }} / {{ maxImages }}</div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        identification: {type: String},
        elementKey: {type: String},
        definition: {type: Array},

        multi: {default: false},
        maxImages: {default: 9999, type: Number},
    },
    methods: {
        getFieldId: function () {
            return this.identification + "_wrapper";
        }
    },

    created() {
    },
    mounted() {
        var component = this;
        this.$nextTick(function () {
            var sortableGallery = new Sortable(
                document.getElementById(component.getFieldId()),
                {
                    group: component.identification,
                    animation: 150,
                    fallbackOnBody: true,
                    swapThreshold: 0.65,
                    dataIdAttr: 'data-mediaid',
                    draggable: ".cms-image-picker",
                    handle: ".reorder-handle",
                    store: {}
                });
        })
    },
    data() {
        return {
            isMulti: this.multi !== null && this.multi !== false,
            isEmpty: this.definition === null || this.definition.length === 0,

            items: this.definition ?? [],
            mediaPickers: [],
            mediaPickerCount: 0
        }
    },
    computed: {
        canAddAnother: function () {
            return this.multi !== null && this.multi !== false && typeof this.mediaPickers !== 'undefined' && this.imagesCount <= this.maxImages;
        },
        imagesCount: function () {
            return this.mediaPickers.length;
        }
    },
}
</script>
