require('./bootstrap');
require('alpinejs');
require('daterangepicker');

require('../assets/plugins/chart/chart.bundle.js')
require('../assets/plugins/chart/utils.js')

require('../assets/plugins/simplebar/js/simplebar.min.js')

require('./mobile-menu');
require('./sidemenu');
// require('../assets/plugins/sidemenu-responsive-tabs/js/sidemenu')

require('../assets/js/custom.js');
require('@fortawesome/fontawesome-pro/js/all');

import Vue from 'vue';
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import flatpickr from "flatpickr";
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import skLocale from '@fullcalendar/core/locales/sk';
import PerfectScrollbar from 'perfect-scrollbar';

window.flatpickr = flatpickr;

window.Calendar         = Calendar;
window.dayGridPlugin    = dayGridPlugin;
window.bootstrap5Plugin = bootstrap5Plugin;
window.timeGridPlugin   = timeGridPlugin;
window.listPlugin       = listPlugin;
window.skLocale         = skLocale;

var moment    = require('moment');
window.moment = moment;
require('jquery-ui/ui/widget');
require('jquery-ui/ui/scroll-parent');
require('jquery-ui/ui/data');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/ie');
require('jquery-ui/ui/widgets/sortable');
require('select2');

const Swal = window.Swal = require('sweetalert2');

require('select2/dist/js/select2.full.min');
require('./jquery.print.min');

window.Sortable = require('sortablejs').Sortable;

window.cms = {
    mediaPickerCount: 0, mediaPickers: {}
};

let relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
var momentTZ = require('moment-timezone');

Vue.prototype.dayjs = dayjs;
window.dayjs        = dayjs;

Object.defineProperty(Vue.prototype, '$dayjs', {value: dayjs});
let linkPicker       = require('../components/LinkPicker').default;
let imagePicker      = require('../components/ImagePicker').default;
let multiImagePicker = require('../components/MultiImagePicker').default;
let vueComponents    = {};

vueComponents['linkPicker']       = Vue.component('link-picker', linkPicker);
vueComponents['imagePicker']      = Vue.component('image-picker', imagePicker);
vueComponents['multiImagePicker'] = Vue.component('multi-image-picker', multiImagePicker);

Vue.extend(linkPicker);
Vue.extend(imagePicker);
Vue.extend(multiImagePicker);

window.vueComponents = vueComponents;

let app = new Vue({
                      el: '#app', components: {
        linkPicker, imagePicker, multiImagePicker
    }
                  });

window.vueApp = app;

$(document).ready(function () {
    $('.select2').select2();

    const ps = new PerfectScrollbar(
        '.app-sidebar',
        {
            useBothWheelAxes: true,
            suppressScrollX : true,
        });
});

$('body').on('click', '.forceJsPrint', function (e) {
    e.preventDefault();
    $(this).doPrintPage({
                            attr: 'href', message: 'Váš dokument sa pripravuje na tlač.'
                        });
});

window.replaceCommaToDot = function (target, event) {
    $(target).val($(target).val().replace(',', '.'));
}

window.isNumberKey = function (txt, evt) {
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    //console.log(charCode);
    if (charCode == 44 || charCode == 188) {
        //Check if the text already contains the . character
        if ((txt.value.indexOf(',') == -1 && txt.value.indexOf('.') == -1) && txt.value.length > 0) {
            return true;
        }
        return false;
    } else if (charCode == 46 || charCode == 190 || charCode == 110) {
        //Check if the text already contains the . character
        if ((txt.value.indexOf(',') == -1 && txt.value.indexOf('.') == -1) && txt.value.length > 0) {
            return true;
        }
        return false;
    } else {
        if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 || charCode <= 105)) {
            return true;
        }
        return false;
    }
    return true;
}
