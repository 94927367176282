<template>
    <div :id="getFieldId()"
         class="cms-link-picker col-6 col-xl-6"
         v-bind:class="{empty: isEmpty}">
        <div class="cms-link-picker--wrapper">
            <input type="hidden" :name="getAttrName('url')"
                   class="cms-link-picker--vue"
                   :id="getFieldId('url')"
                   :value="attrUrl">
            <input type="hidden" :name="getAttrName('type')"
                   :id="getFieldId('type')"
                   :value="attrType">
            <input type="hidden" :name="getAttrName('title')"
                   :id="getFieldId('title')"
                   :value="attrTitle">
            <input type="hidden" :name="getAttrName('anchor')"
                   :id="getFieldId('anchor')"
                   :value="attrAnchor">
            <input type="hidden" :name="getAttrName('target')"
                   :id="getFieldId('target')"
                   :value="attrTarget">

            <div class="placeholder btn btn-dark" @click="createLink" v-bind:class="{empty: isEmpty}">
                <i class="far fa-link mr-2"></i> Create a link
            </div>

            <div class="link-data" v-bind:class="{empty: isEmpty}">
                <div>Url: {{ this.fetchedUrl }} [ {{ this.attrUrl }} ]</div>
                <div>Anchor: {{ this.attrAnchor }}</div>
                <div>Title: {{ this.attrTitle }}</div>
                <div>Target: {{ this.attrTarget }}</div>
            </div>
            <div class="trash" v-bind:class="{empty: isEmpty}"
                 v-on:click="showConfirmationDialog('Are you sure you want to remove this link ?')">
                <i class="fas fa-trash-alt"></i>
            </div>
        </div>
    </div>
</template>


<style scoped>

.placeholder {
    cursor : pointer;
}

.cms-link-picker .link-data.empty,
.cms-link-picker .trash.empty,
.cms-link-picker .placeholder {
    display : none;
}

.cms-link-picker .trash,
.cms-link-picker .placeholder.empty {
    display : inline-block;
}
</style>

<script>
export default {
    props  : {
        identification: {type: String},
        elementKey    : {type: String},
        placeholder   : {type: Boolean, default: false},
        definition    : {type: Object}
    },
    methods: {
        createLink : function () {
            this.linkModalInit();
        },
        actualUrl  : async function () {
            var nodeType = 'cms';
            var that     = this;

            async function axiosTest(nodeType, nodeId) {
                return await axios.get(route('ajax.node.get-url', {
                    type   : nodeType,
                    node_id: nodeId
                })).then(response => response.data)
            }

            if (this.attrType === 0) {
                let node = await axiosTest(nodeType, this.attrUrl);
                console.log(node['node']['url']);
                that.fetchedUrl = node['node']['url'];
            }
        },
        clear      : function () {
            $('#' + this.getFieldIdEscaped() + " input").attr('value', '');
            this.def.url    = null;
            this.def.type   = 1;
            this.def.title  = null;
            this.def.target = null;
            this.def.anchor = null;
        },
        trash      : function () {
            this.clear();
        },
        getAttrName: function (fieldName) {
            if (this.multi) {
                return 'form_input_' + this.elementKey + '[' + this.currentId + '][' + fieldName + ']'
            }
            return 'form_input_' + this.elementKey + '[' + fieldName + ']';
        },
        getFieldId : function (fieldName = null, fieldId = null) {
            var name = this.identification;
            if (this.multi) {
                name = name + '_' + (fieldId ?? this.currentId);
            }
            if (fieldName !== null) {
                name = name + "_" + fieldName;
            }
            return name;
        },
        getFieldIdEscaped(fieldName = null, fieldId = null) {
            return this.getFieldId(fieldName, fieldId).replace('.', '\\.');
        },
        showConfirmationDialog: function (text) {
            var component = this;
            bootbox.confirm(
                text,
                function (result) {
                    if (result) {
                        component.trash()
                    }
                })
        },
        dataChanged           : function () {
            var component   = this;
            var urlValue    = $('#' + component.getFieldIdEscaped('url')).val();
            var anchorValue = $('#' + component.getFieldIdEscaped('anchor')).val();
            var targetValue = $('#' + component.getFieldIdEscaped('target')).val();
            var typeValue   = $('#' + component.getFieldIdEscaped('type')).val();

        },
        linkModalInit() {
            let modal = $('#link-modal');

            modal.modal('show');
        }
    },

    created() {
    },
    mounted() {
        var baseSelector = "#" + this.getFieldIdEscaped();
        this.actualUrl();
    },
    data() {
        return {
            type      : this.definition?.type ?? 0,
            def       : this.definition ?? {url: null, type: 0},
            fetchedUrl: '<em>-- loading --</em>'
        }
    },
    computed: {
        attrUrl   : function () {
            return this.def && this.def.url !== null ? this.def.url : ''
        },
        attrType  : function () {
            return this.def && this.def.type !== null ? this.def.type : 0
        },
        attrTitle : function () {
            return this.def && this.def.title !== null ? this.def.title : ''
        },
        attrAnchor: function () {
            return this.def && this.def.anchor !== null ? this.def.anchor : ''
        },
        attrTarget: function () {
            return this.def && this.def.target !== null ? this.def.target : ''
        },
        isEmpty   : function () {
            //console.log(this.url, this.def, typeof this.def === 'undefined', this.def.url)
            //return this.url === null && (_.isEmpty(this.def) || this.def === null || typeof this.def === 'undefined' || this.def.url === null)
            return this.attrUrl === null || this.attrUrl === '';
        }
    }
}
</script>
