window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    global.$      = global.jQuery = window.$ = window.jQuery = require('jquery');

    require('./jquery.inputmask')
    require('jquery.fancytree')

    require('bootstrap');
    global.bootbox = window.bootbox = require('bootbox');

    require('datatables.net-bs4');
    require('datatables.net-buttons-bs4');
    require('datatables.net-select-bs4');
    require('datatables.net-editor-bs4');
    require('datatables.net-select-bs4')();
    require('datatables.net-buttons/js/buttons.colVis.js')();
    require('datatables.net-buttons/js/buttons.flash.js')();
    require('datatables.net-buttons/js/buttons.html5.js')();
    require('datatables.net-buttons/js/buttons.print.js')();
    require('datatables.net-colreorder-bs4')();
    require('datatables.net-fixedcolumns-bs4')();
    require('datatables.net-fixedheader-bs4')();
    require('datatables.net-keytable-bs4')();
    require('datatables.net-responsive-bs4')();
    require('datatables.net-rowgroup-bs4')();
    require('datatables.net-rowreorder-bs4')();
    require('datatables.net-scroller-bs4')();
    require('datatables.net-searchbuilder-bs4')();
    require('datatables.net-searchpanes-bs4')();
    require('datatables.net-datetime');
} catch (e) {
}

var csrfToken = $('meta[name="csrf-token"]').attr('content');

$.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': csrfToken
                }
            });

import helper from './helper'

window.helper = helper

window.axios                                             = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN']     = csrfToken;
